

.App {
  text-align: center;
  align-content: center;
  align-items: center;
  display: block;

}
.pdf-document {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;


}
